import Grid from '@components/common/Grid';
import React from 'react';
import SEO from '@components/common/SEO';
import { graphql } from 'gatsby';
import { renderBlocks } from '@src/helpers';

const Page = ({ data, pageContext }) => {
  const {
    seo,
    ACFHomepage: { content },
  } = data.wpPage;
  const { language: lang, id } = pageContext;

  return (
    <>
      <SEO seo={seo} language={lang} />
      <Grid animKey={`page-${id}`} transition>
        {renderBlocks(content)}
      </Grid>
    </>
  );
};

export const data = graphql`
  query Homepage($id: String) {
    wpPage(id: { eq: $id }) {
      ACFHomepage {
        content {
          ... on WpPage_Acfhomepage_Content_Copy {
            align
            bodyType
            columns
            copy
            fieldGroupName
            title
          }
          ... on WpPage_Acfhomepage_Content_ArtistsList {
            fieldGroupName
            title
            marginTop
            link {
              url
              title
            }
            artists {
              ... on WpArtist {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      width
                      height
                      sizes {
                        height
                        name
                        sourceUrl
                        width
                      }
                    }
                    mediaItemUrl
                  }
                }
              }
            }
          }
          ... on WpPage_Acfhomepage_Content_LocationsList {
            fieldGroupName
            title
            marginTop
            locations {
              ... on WpLocation {
                id
                uri
                title
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      width
                      height
                      sizes {
                        height
                        name
                        sourceUrl
                        width
                      }
                    }
                    mediaItemUrl
                  }
                }
              }
            }
          }
          ... on WpPage_Acfhomepage_Content_ArticlesList {
            fieldGroupName
            title
            marginTop
            link {
              url
              title
            }
            articles {
              ... on WpArticle {
                id
                uri
                title
                date
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      width
                      height
                      sizes {
                        height
                        name
                        sourceUrl
                        width
                      }
                    }
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`;

export default Page;
